<template>
  <div style="width: 100%;">
    <el-upload
      v-show="false"
      id="quill-upload"
      action="/wholesale/api/app/meiye/upload/project"
      accept="image/jpg,image/jpeg,image/png,image/gif"
      name="image"
      multiple
      :limit="3"
      :data="uploadData"
      list-type="picture"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-error="uploadError"
      :on-success="handleExceed"
    >
      <el-button size="small" type="primary"></el-button>
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/jpeg/png/gif文件，且不超过2M
      </div>
    </el-upload>
    <el-row v-loading="uillUpdateImg">
      <quillEditor
        ref="myQuillEditor"
        @change="onEditorChange($event)"
        v-model="value"
        :options="editorOption"
      />
    </el-row>
  </div>
</template>
<script>
import { Quill, quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);
export default {
  name: "richText",
  components: { quillEditor },
  props: ["content"], // 父组件传过来的，将传过来的渲染到富文本编辑器
  data() {
    return {
      token: '',
      uploadData: {
        token: sessionStorage.getItem('token') || ''
      },
      uillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画
      serverUrl: "/api/app/meiye/upload/project", // 上传的图片服务器地址
      value: this.content, // 富文本内容
      editorOption: {
        // 符文本编辑器的配置
        placeholder: "",
        theme: "snow",
        modules: {
          imageResize: { // 放大缩小
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
          },
          toolbar: {
            container: [
              // 工具栏配置, 默认是全部
              ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
              ['blockquote', 'code-block'], // 引用，代码块
              [{'header': 1}, {'header': 2}], // 标题，键值对的形式；1、2表示字体大小
              [{'list': 'ordered'}, {'list': 'bullet'}], // 列表
              [{'script': 'sub'}, {'script': 'super'}], // 上下标
              [{'indent': '-1'}, {'indent': '+1'}], // 缩进
              [{'direction': 'rtl'}], // 文本方向
              [{'size': ['small', false, 'large', 'huge']}], // 字体大小
              [{'header': [1, 2, 3, 4, 5, 6, false]}], // 几级标题
              [{'color': []}, {'background': []}], // 字体颜色，字体背景颜色
              [{'font': []}], // 字体
              [{'align': []}], // 对齐方式
              ['clean'], // 清除字体样式
              ['image'] // 链接，上传图片
            ],
            handlers: {
              'image': function(value) {
                if (value) {
                  // 给个点击触发Element-ui，input框选择图片文件
                  document.querySelector("#quill-upload input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
    };
  },
  methods: {
    onEditorChange({ html }) {
      // 富文本编辑器内容发生改变的时候
      this.value = html;
      this.$emit("textChange", html); // 将富文本编辑器输入的文本发送给父组件，父组件涉及提交添加或者更改
    },
    beforeUpload(file) {
      const isIMAGE = file.type === 'image/jpeg'||'image/gif'||'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2MB!');
        return false;
      }
      
      // 上传图片之前开启loading
      this.uillUpdateImg = true;
      return isIMAGE && isLt2M;
    },
    uploadError() {
      // 图片上传失败，关闭loading
      this.uillUpdateImg = false;
      this.$message.error("图片插入失败");
    },
    handleExceed(response, file, fileList) {
      console.log('file', file);
      // 图片添加成功
      let quill = this.$refs.myQuillEditor.quill;
      console.log(response);
      if (response.data.successful === 1) {
        let length = quill.getSelection().index;
        // 插入图片 response.data.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", response.data.data.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      this.fileList = fileList;
      this.uillUpdateImg = false;
    },
  },
  watch: {
    content: {
      immediate: true,
      handler (val) {
        this.value = val;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  /deep/ .ql-container {
    height: 500px;
  }
</style>
